import React, { useEffect, useState } from 'react';
import './singleBlog.scss';
import $ from 'jquery';

///IMPORT IMAGES
import NoUserAvatar from '../../assets/images/no-user-avatar.png';
import Post1 from '../../assets/images/post/post-1.jpeg';
import BottomArrow from '../../assets/images/bottom_arrow_icon.svg';
import Envelope from '../../assets/images/icon/envelope.png';
import Facebook from '../../assets/images/icon/facebook.png';
import Twitter from '../../assets/images/icon/twitter.png';

const Home = () => {

   const [tocToggle, setTocToggle] = useState(false);

   useEffect(() => {
      let firstImageHeight = $('.single-blog__wrapper .single-blog__body .single-blog__main .post__content .post-image')[0].offsetTop;
      $('.single-blog__wrapper .single-blog__body .side-bar__left').css('padding-top', `${firstImageHeight}px`);

      $(window).resize(() => {
         let firstImageHeight = $('.single-blog__wrapper .single-blog__body .single-blog__main .post__content .post-image')[0].offsetTop;
         $('.single-blog__wrapper .single-blog__body .side-bar__left').css('padding-top', `${firstImageHeight}px`);
      });

      $('.toc .toc__container .toc__list .toc__item a').on('click', (e) => {
         $('.toc .toc__container .toc__list .toc__item a').css('color', 'var(--black)').css('font-weight', '');
         $(e.currentTarget).css('color', 'var(--primary)').css('font-weight', '500');
         setTocToggle(false);
      })
   })

   return (
      <div className='scaffold-wrapper single-blog__wrapper'>
         <div className="scaffold-wrapper__inner">

            <div className="single-blog__body">
               <div className="side-bar__left">
                  <div className="post-info__author">
                     <div className="author-info">
                        <div className="author-avatar">
                           <img src={NoUserAvatar} className='no-user-avatar' alt="" />
                        </div>
                        <p>By <span>Editorial Staff</span>
                        </p>
                     </div>
                     <p className='post-info__date'>Published on Jan 20, 2024.</p>
                     <div className="post-read__time">
                        <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                           <path className="cls-1" d="M16,31A15,15,0,1,1,31,16,15,15,0,0,1,16,31ZM16,3A13,13,0,1,0,29,16,13,13,0,0,0,16,3Z" style={{ fill: 'var(--primary)' }} />
                           <path className="cls-1" d="M20.24,21.66l-4.95-4.95A1,1,0,0,1,15,16V8h2v7.59l4.66,4.65Z" style={{ fill: 'var(--primary)' }} />
                        </svg>
                        <p>3 minute read</p>
                     </div>
                  </div>

                  <div className="toc toc__sticky">
                     <div className="toc__container">
                        <h2 className='toc__heading'>Table of Contents</h2>

                        <ul className='toc__list'>
                           <li className="toc__item">
                              <a href="#title-1" className='toc__link'>
                                 Introduction
                              </a>
                           </li>
                           <li className="toc__item">
                              <a href="#title-2" className='toc__link'>
                                 What is Adult Depression?
                              </a>
                           </li>
                           <li className="toc__item">
                              <a href="#title-3" className='toc__link'>
                                 Recognizing the Signs of Depression
                              </a>
                           </li>
                           <li className="toc__item">
                              <a href="#title-4" className='toc__link'>
                                 Causes and Risk Factors
                              </a>
                           </li>
                           <li className="toc__item">
                              <a href="#title-5" className='toc__link'>
                                 Treatment and Management
                              </a>
                           </li>
                           <li className="toc__item">
                              <a href="#title-6" className='toc__link'>
                                 The Importance of Seeking Help
                              </a>
                           </li>
                           <li className="toc__item">
                              <a href="#title-7" className='toc__link'>
                                 Supporting Someone with Depression
                              </a>
                           </li>
                           <li className="toc__item">
                              <a href="#title-8" className='toc__link'>
                                 Coping Strategies for Those with Depression
                              </a>
                           </li>
                           <li className="toc__item">
                              <a href="#title-9" className='toc__link'>
                                 Conclusion
                              </a>
                           </li>
                           <li className="toc__item">
                              <a href="#title-10" className='toc__link'>
                                 Additional Resources
                              </a>
                           </li>
                        </ul>
                     </div>
                  </div>
               </div>

               <div className="single-blog__main">
                  <nav className='breadcrumb'>
                     <ol className='breadcrumb__list'>
                        <li className='breadcrumb__item'>
                           <a href="/" className="breadcrumb__link"></a>
                        </li>
                        <li className='breadcrumb__item'>
                           <a href="/" className="breadcrumb__link">Ailments</a>
                        </li>
                        <li className='breadcrumb__item'>
                           <a href="/" className="breadcrumb__link">Doctors</a>
                        </li>
                     </ol>
                  </nav>

                  <article className='post__content'>
                     <h1 className="post-title">Navigating Through the Fog: Understanding and Managing Adult Depression</h1>

                     <div className="post-date-time__info">
                        <span className='post-created__date'>Published on Jan 20, 2024.</span>

                        <div className="post-read__time">
                           <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path className="cls-1" d="M16,31A15,15,0,1,1,31,16,15,15,0,0,1,16,31ZM16,3A13,13,0,1,0,29,16,13,13,0,0,0,16,3Z" style={{ fill: 'var(--primary)' }}></path><path className="cls-1" d="M20.24,21.66l-4.95-4.95A1,1,0,0,1,15,16V8h2v7.59l4.66,4.65Z" style={{ fill: 'var(--primary)' }}></path></svg>
                           <p>3 minute read</p>
                        </div>
                     </div>

                     <div className="toc toc__sticky" style={{ height: tocToggle ? 'auto' : '42px', transition: 'height .5s linear' }}>
                        <div className="toc__container">
                           <h2 className='toc__heading' onClick={() => !tocToggle ? setTocToggle(true) : setTocToggle(false)}>Table of Contents</h2>

                           <button className='toc-toggle__active' style={{ transform: `rotate(${tocToggle ? 180 : 0}deg)` }} onClick={() => !tocToggle ? setTocToggle(true) : setTocToggle(false)}>
                              <img src={BottomArrow} alt="" />
                           </button>

                           <ul className='toc__list'>
                              <li className="toc__item">
                                 <a href="#title-1" className='toc__link'>
                                    Introduction
                                 </a>
                              </li>
                              <li className="toc__item">
                                 <a href="#title-2" className='toc__link'>
                                    What is Adult Depression?
                                 </a>
                              </li>
                              <li className="toc__item">
                                 <a href="#title-3" className='toc__link'>
                                    Recognizing the Signs of Depression
                                 </a>
                              </li>
                              <li className="toc__item">
                                 <a href="#title-4" className='toc__link'>
                                    Causes and Risk Factors
                                 </a>
                              </li>
                              <li className="toc__item">
                                 <a href="#title-5" className='toc__link'>
                                    Treatment and Management
                                 </a>
                              </li>
                              <li className="toc__item">
                                 <a href="#title-6" className='toc__link'>
                                    The Importance of Seeking Help
                                 </a>
                              </li>
                              <li className="toc__item">
                                 <a href="#title-7" className='toc__link'>
                                    Supporting Someone with Depression
                                 </a>
                              </li>
                              <li className="toc__item">
                                 <a href="#title-8" className='toc__link'>
                                    Coping Strategies for Those with Depression
                                 </a>
                              </li>
                              <li className="toc__item">
                                 <a href="#title-9" className='toc__link'>
                                    Conclusion
                                 </a>
                              </li>
                              <li className="toc__item">
                                 <a href="#title-10" className='toc__link'>
                                    Additional Resources
                                 </a>
                              </li>
                           </ul>
                        </div>
                     </div>

                     <div className="post-image">
                        <img src={Post1} alt="" />
                     </div>

                     <h1 className='post-description__title' id='title-1'>Introduction</h1>

                     <p className="post-description__content">Depression in adults is a common yet often misunderstood condition that affects millions worldwide. It's more than just feeling sad or going through a rough patch; it's a serious mental health issue that can profoundly impact every aspect of an adult's life. This blog aims to shed light on adult depression, its signs, treatment options, and ways to support those who are struggling.</p>

                     <div className="ad_wrapper">
                        <div className="ad-wrapper__title">
                           <span>Related searches</span>
                        </div>

                        <div className="ad-wrapper__list">
                           <div className="ad-wrapper__item">
                              <a href="https://paid-depression-clinical-trials-us-lp1.info/serp?sc=Psj0przUFaZA20&query=Up+to+$3000+Paid+Depression+Trials+Baku&afdToken=ChMIwv6g6K7vgwMV-u67CB3xogpREmwBlLqpj_NhBskrX9HPHiGvJ-ym4P31qjhLb-2zw2aHzh4GpYyu12suYVzwxEtP6v07wCy83ylpd2Dn_1brZbk21X2fywNq5PDagMLk0_jKD57GtkrK3SV6dTawm4YVZYPQqGXG4WBKRgnCMdw&nb=0&rurl=http%3A%2F%2Flocalhost%3A3000%2F&nm=8&nx=308&ny=107&is=500x810&clkt=128" target='_blank'>
                                 <div className="ad-wrapper-item__button">
                                    <div className="item-inner__wrapper">
                                       <svg className="feather feather-chevron-right" fill="none" height="24" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                                          <polyline points="9 18 15 12 9 6" />
                                       </svg>
                                       <span>Up to $3000 Paid Depression Trials</span>
                                    </div>
                                    <svg className="feather feather-chevron-right" fill="none" height="24" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                                       <polyline points="9 18 15 12 9 6" />
                                    </svg>
                                 </div>
                              </a>
                           </div>
                           <div className="ad-wrapper__item">
                              <a href="https://paid-depression-clinical-trials-us-lp1.info/serp?sc=Psj0przUFaZA20&query=$2000+Paid+Depression+Research+Study+Baku+City&afdToken=ChMI0p_68q7vgwMVbPW7CB0DAgMvEmwBlLqpj--ab9wtSZvgT6RjAjCRXI4xlpNmUBYpIFCA0RUPz41rc1EgpSYk6zuOaIc39HroODvUz0Inw_n8IdgZoTqPPCUuIK1TwRrMC6iZeCGnOlH3XzxPBzBij4aNB1at0c4ocsmgqtG7IV8&nb=0&rurl=http%3A%2F%2Flocalhost%3A3000%2F&nm=5&nx=238&ny=77&is=500x810&clkt=128"target='_blank'>
                                 <div className="ad-wrapper-item__button">
                                    <div className="item-inner__wrapper">
                                       <svg className="feather feather-chevron-right" fill="none" height="24" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                                          <polyline points="9 18 15 12 9 6" />
                                       </svg>
                                       <span>$2000 Paid Depression Research Study</span>
                                    </div>
                                    <svg className="feather feather-chevron-right" fill="none" height="24" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                                       <polyline points="9 18 15 12 9 6" />
                                    </svg>
                                 </div>
                              </a>
                           </div>
                           <div className="ad-wrapper__item">
                              <a href="https://paid-depression-clinical-trials-us-lp1.info/serp?sc=Psj0przUFaZA20&query=Depression+Apnea+Studies+Baku+$2000&afdToken=ChMIipqJgq_vgwMVJYf9Bx1yxA7sEmwBlLqpjycYJoFCUwnExDKxe1J1NXwbELoUO7cygZXqqLkgkf-i8GPd8Bll_p22W2GsGUZGtFc9Si_JzjVZCeph3X5FQL3KkaAuRFRv38MVSJQMRmfrIaJdUsi5szElX3ow9F0TW4euGZCcecs&nb=0&rurl=http%3A%2F%2Flocalhost%3A3000%2F&nm=7&nx=217&ny=92&is=500x810&clkt=94" target='_blank'>
                                 <div className="ad-wrapper-item__button">
                                    <div className="item-inner__wrapper">
                                       <svg className="feather feather-chevron-right" fill="none" height="24" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                                          <polyline points="9 18 15 12 9 6" />
                                       </svg>
                                       <span>Depression Apnea Studies $2000</span>
                                    </div>
                                    <svg className="feather feather-chevron-right" fill="none" height="24" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                                       <polyline points="9 18 15 12 9 6" />
                                    </svg>
                                 </div>
                              </a>
                           </div>
                           <div className="ad-wrapper__item">
                              <a href="https://paid-depression-clinical-trials-us-lp1.info/serp?sc=Psj0przUFaZA20&query=Depression+Study+Compensation+up+to+$3000&afdToken=ChMI6N_xna_vgwMVv4H9Bx2pjQwaEnEBlLqpj4CwM17ndgCFxTPRvDWy7ep9hUyHa8dgEEOuO2c1fU9g4ZRgg5gVHW5u0Go-aKEwk_B9vd3cAbwGKOnSxCXeAkYJ-OuwImaOT9I4qQeaFx9cuexZabYfepH0UDDdKexxrgWPop6L0c6d8l_YxA&nb=0&rurl=http%3A%2F%2Flocalhost%3A3000%2F&nm=6&nx=179&ny=59&is=500x810&clkt=134" target='_blank'>
                                 <div className="ad-wrapper-item__button">
                                    <div className="item-inner__wrapper">
                                       <svg className="feather feather-chevron-right" fill="none" height="24" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                                          <polyline points="9 18 15 12 9 6" />
                                       </svg>
                                       <span>Depression Study Compensation up to $3000</span>
                                    </div>
                                    <svg className="feather feather-chevron-right" fill="none" height="24" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                                       <polyline points="9 18 15 12 9 6" />
                                    </svg>
                                 </div>
                              </a>
                           </div>
                           <div className="ad-wrapper__item">
                              <a href="https://paid-depression-clinical-trials-us-lp1.info/serp?sc=Psj0przUFaZA20&query=Paid+Depresiion+Studies+Near+Me&afdToken=ChMIpIjIi6_vgwMVUor9Bx0WXQ9yEmwBlLqpj9nFM5xQWj334guCJXj4mvlcidtQpxfTJTiv7Ip8lJO8jgVXgaeCTUFDkz1d58f2kBaNuwjGBRmZAvfHkCQz2bLRgMmr8ugxLCGW8tfpuz498F6NssbYYgdR4OO4MCn7YoZW7oE0ZhE&nb=0&rurl=http%3A%2F%2Flocalhost%3A3000%2F&nm=11&nx=354&ny=53&is=500x810&clkt=82" target='_blank'>
                                 <div className="ad-wrapper-item__button">
                                    <div className="item-inner__wrapper">
                                       <svg className="feather feather-chevron-right" fill="none" height="24" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                                          <polyline points="9 18 15 12 9 6" />
                                       </svg>
                                       <span>Paid Depression Studies Near Me</span>
                                    </div>
                                    <svg className="feather feather-chevron-right" fill="none" height="24" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                                       <polyline points="9 18 15 12 9 6" />
                                    </svg>
                                 </div>
                              </a>
                           </div>
                        </div>
                     </div>

                     <h1 className='post-description__title' id='title-2'>What is Adult Depression?</h1>

                     <p className="post-description__content">Depression in adults is a complex mental health disorder characterized by persistent feelings of sadness, hopelessness, and a lack of interest or pleasure in activities. It can lead to a range of emotional and physical problems and can impair a person's ability to function at work and at home.</p>

                     <h1 className="post-description__title" id='title-3'>Recognizing the Signs of Depression</h1>

                     <p className='post-description__content'>Unlike temporary emotional responses to life's challenges, depression lasts longer and is more intense. Common symptoms include:</p>

                     <ul className='post-description__list'>
                        <li>Persistent sad, anxious, or "empty" mood</li>
                        <li>Feelings of hopelessness or pessimism</li>
                        <li>Irritability</li>
                        <li>Loss of interest in hobbies and activities</li>
                        <li>Decreased energy or fatigue</li>
                        <li>Difficulty concentrating, remembering, or making decisions</li>
                        <li>Changes in appetite or weight</li>
                        <li>Thoughts of death or suicide</li>
                     </ul>

                     <h1 className='post-description__title' id='title-4'>Causes and Risk Factors</h1>

                     <p className='post-description__content'>There's no single cause of depression in adults. It can result from a combination of genetic, biological, environmental, and psychological factors. Risk factors include a family history of depression, major life changes, trauma, or stress. Certain medical conditions and medications can also contribute to or exacerbate depression.</p>

                     <h1 className='post-description__title' id='title-5'>Treatment and Management</h1>

                     <p className='post-description__content'>Depression is treatable, and most people benefit from some combination of the following:</p>

                     <ul className='post-description__list'>
                        <li>Psychotherapy: Talking therapies, like cognitive-behavioral therapy (CBT), can help individuals change negative thinking patterns and improve coping strategies.</li>
                        <li>Medication: Antidepressants can be effective in managing symptoms of depression.</li>
                        <li>Lifestyle Changes: Regular exercise, a healthy diet, adequate sleep, and reducing stress can have a significant positive impact.</li>
                     </ul>

                     <h1 className='post-description__title' id='title-6'>The Importance of Seeking Help</h1>

                     <div className="ad_wrapper">
                        <div className="ad-wrapper__title">
                           <span>Related searches</span>
                        </div>

                        <div className="ad-wrapper__list">
                           <div className="ad-wrapper__item">
                              <a href="https://paid-depression-clinical-trials-us-lp1.info/serp?sc=Psj0przUFaZA20&query=Up+to+$3000+Paid+Depression+Trials+Baku&afdToken=ChMIwv6g6K7vgwMV-u67CB3xogpREmwBlLqpj_NhBskrX9HPHiGvJ-ym4P31qjhLb-2zw2aHzh4GpYyu12suYVzwxEtP6v07wCy83ylpd2Dn_1brZbk21X2fywNq5PDagMLk0_jKD57GtkrK3SV6dTawm4YVZYPQqGXG4WBKRgnCMdw&nb=0&rurl=http%3A%2F%2Flocalhost%3A3000%2F&nm=8&nx=308&ny=107&is=500x810&clkt=128" target='_blank'>
                                 <div className="ad-wrapper-item__button">
                                    <div className="item-inner__wrapper">
                                       <svg className="feather feather-chevron-right" fill="none" height="24" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                                          <polyline points="9 18 15 12 9 6" />
                                       </svg>
                                       <span>Up to $3000 Paid Depression Trials</span>
                                    </div>
                                    <svg className="feather feather-chevron-right" fill="none" height="24" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                                       <polyline points="9 18 15 12 9 6" />
                                    </svg>
                                 </div>
                              </a>
                           </div>
                           <div className="ad-wrapper__item">
                              <a href="https://paid-depression-clinical-trials-us-lp1.info/serp?sc=Psj0przUFaZA20&query=$2000+Paid+Depression+Research+Study+Baku+City&afdToken=ChMI0p_68q7vgwMVbPW7CB0DAgMvEmwBlLqpj--ab9wtSZvgT6RjAjCRXI4xlpNmUBYpIFCA0RUPz41rc1EgpSYk6zuOaIc39HroODvUz0Inw_n8IdgZoTqPPCUuIK1TwRrMC6iZeCGnOlH3XzxPBzBij4aNB1at0c4ocsmgqtG7IV8&nb=0&rurl=http%3A%2F%2Flocalhost%3A3000%2F&nm=5&nx=238&ny=77&is=500x810&clkt=128"target='_blank'>
                                 <div className="ad-wrapper-item__button">
                                    <div className="item-inner__wrapper">
                                       <svg className="feather feather-chevron-right" fill="none" height="24" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                                          <polyline points="9 18 15 12 9 6" />
                                       </svg>
                                       <span>$2000 Paid Depression Research Study</span>
                                    </div>
                                    <svg className="feather feather-chevron-right" fill="none" height="24" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                                       <polyline points="9 18 15 12 9 6" />
                                    </svg>
                                 </div>
                              </a>
                           </div>
                           <div className="ad-wrapper__item">
                              <a href="https://paid-depression-clinical-trials-us-lp1.info/serp?sc=Psj0przUFaZA20&query=Depression+Apnea+Studies+Baku+$2000&afdToken=ChMIipqJgq_vgwMVJYf9Bx1yxA7sEmwBlLqpjycYJoFCUwnExDKxe1J1NXwbELoUO7cygZXqqLkgkf-i8GPd8Bll_p22W2GsGUZGtFc9Si_JzjVZCeph3X5FQL3KkaAuRFRv38MVSJQMRmfrIaJdUsi5szElX3ow9F0TW4euGZCcecs&nb=0&rurl=http%3A%2F%2Flocalhost%3A3000%2F&nm=7&nx=217&ny=92&is=500x810&clkt=94" target='_blank'>
                                 <div className="ad-wrapper-item__button">
                                    <div className="item-inner__wrapper">
                                       <svg className="feather feather-chevron-right" fill="none" height="24" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                                          <polyline points="9 18 15 12 9 6" />
                                       </svg>
                                       <span>Depression Apnea Studies $2000</span>
                                    </div>
                                    <svg className="feather feather-chevron-right" fill="none" height="24" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                                       <polyline points="9 18 15 12 9 6" />
                                    </svg>
                                 </div>
                              </a>
                           </div>
                           <div className="ad-wrapper__item">
                              <a href="https://paid-depression-clinical-trials-us-lp1.info/serp?sc=Psj0przUFaZA20&query=Depression+Study+Compensation+up+to+$3000&afdToken=ChMI6N_xna_vgwMVv4H9Bx2pjQwaEnEBlLqpj4CwM17ndgCFxTPRvDWy7ep9hUyHa8dgEEOuO2c1fU9g4ZRgg5gVHW5u0Go-aKEwk_B9vd3cAbwGKOnSxCXeAkYJ-OuwImaOT9I4qQeaFx9cuexZabYfepH0UDDdKexxrgWPop6L0c6d8l_YxA&nb=0&rurl=http%3A%2F%2Flocalhost%3A3000%2F&nm=6&nx=179&ny=59&is=500x810&clkt=134" target='_blank'>
                                 <div className="ad-wrapper-item__button">
                                    <div className="item-inner__wrapper">
                                       <svg className="feather feather-chevron-right" fill="none" height="24" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                                          <polyline points="9 18 15 12 9 6" />
                                       </svg>
                                       <span>Depression Study Compensation up to $3000</span>
                                    </div>
                                    <svg className="feather feather-chevron-right" fill="none" height="24" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                                       <polyline points="9 18 15 12 9 6" />
                                    </svg>
                                 </div>
                              </a>
                           </div>
                           <div className="ad-wrapper__item">
                              <a href="https://paid-depression-clinical-trials-us-lp1.info/serp?sc=Psj0przUFaZA20&query=Paid+Depresiion+Studies+Near+Me&afdToken=ChMIpIjIi6_vgwMVUor9Bx0WXQ9yEmwBlLqpj9nFM5xQWj334guCJXj4mvlcidtQpxfTJTiv7Ip8lJO8jgVXgaeCTUFDkz1d58f2kBaNuwjGBRmZAvfHkCQz2bLRgMmr8ugxLCGW8tfpuz498F6NssbYYgdR4OO4MCn7YoZW7oE0ZhE&nb=0&rurl=http%3A%2F%2Flocalhost%3A3000%2F&nm=11&nx=354&ny=53&is=500x810&clkt=82" target='_blank'>
                                 <div className="ad-wrapper-item__button">
                                    <div className="item-inner__wrapper">
                                       <svg className="feather feather-chevron-right" fill="none" height="24" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                                          <polyline points="9 18 15 12 9 6" />
                                       </svg>
                                       <span>Paid Depression Studies Near Me</span>
                                    </div>
                                    <svg className="feather feather-chevron-right" fill="none" height="24" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                                       <polyline points="9 18 15 12 9 6" />
                                    </svg>
                                 </div>
                              </a>
                           </div>
                        </div>
                     </div>

                     <p className='post-description__content'>Many adults hesitate to seek help for depression due to stigma or misunderstanding the condition. However, getting professional help is crucial for effective management. Early intervention can lead to better outcomes and prevent the condition from worsening.</p>

                     <h1 className='post-description__title' id='title-7'>Supporting Someone with Depression</h1>

                     <p className='post-description__content'>If someone you know is struggling with depression:</p>

                     <ul className='post-description__list'>
                        <li>Offer emotional support, understanding, patience, and encouragement.</li>
                        <li>Encourage them to seek professional help and offer to help them find a healthcare provider.</li>
                        <li>Be willing to listen without judgment and offer hope.</li>
                        <li>Never ignore comments about suicide, and encourage them to talk to a mental health professional or doctor.</li>
                     </ul>

                     <h1 className='post-description__title' id='title-8'>Coping Strategies for Those with Depression</h1>

                     <ul className='post-description__list'>
                        <li>Set realistic goals and take on a reasonable amount of responsibility.</li>
                        <li>Break large tasks into small ones, and set priorities.</li>
                        <li>Try to spend time with other people and confide in a trusted friend or relative.</li>
                        <li>Participate in activities that may make you feel better.</li>
                        <li>Postpone important decisions until you feel better.</li>
                     </ul>

                     <h1 className="post-description__title" id='title-9'>Conclusion</h1>

                     <p className="post-description__content">Understanding adult depression is the first step towards healing and recovery. It’s important to recognize the signs, seek appropriate treatment, and offer support to those affected. Remember, depression is not a sign of weakness, and with the right help and support, individuals can overcome this condition and lead fulfilling lives.</p>

                     <h1 className="post-description__title" id='title-10'>Additional Resources</h1>

                     <p className="post-description__content">For more information on adult depression, consider visiting [reputable mental health websites] or contacting a mental health professional.</p>

                  </article>

                  <div className="social-share">
                     <h2>Share this article</h2>

                     <div className="social-icons">
                        <a href="/">
                           <img src={Envelope} alt="" />
                        </a>
                        <a href="https://www.facebook.com" target='_blank'>
                           <img src={Facebook} alt="" />
                        </a>
                        <a href="https://www.twitter.com" target='_blank'>
                           <img src={Twitter} alt="" />
                        </a>
                     </div>
                  </div>

                  <div className="author-bio">
                     <div className="author-bio__avatar">
                        <img src={NoUserAvatar} alt="" />
                     </div>

                     <div className="author-bio__info">
                        <h3 className="author-bio__author-name">Editorial Staff</h3>
                        <span className='author-bio__title'>Contributor</span>
                     </div>
                  </div>
               </div>

               <div className="side-bar__right">
                  <div className="side-bar__ad">
                     <p>Advertisement</p>
                  </div>

                  <div className="side-bar__block">
                     <h2 className='section__heading'>Related Posts</h2>

                     <div className="post-module">
                        <div className="post-module__item">
                           <a href="/blog/depression-clinical-studies-near-you" className='post-module__thumb-link'>
                              <img src={Post1} alt="" />
                           </a>
                           <h3 className="post-module__heading">
                              <a href="/blog/depression-clinical-studies-near-you">Navigating Through the Fog: Understanding and Managing Adult Depression</a>
                           </h3>
                           <div className="post-module__read-time">
                              <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                 <path className="cls-1" d="M16,31A15,15,0,1,1,31,16,15,15,0,0,1,16,31ZM16,3A13,13,0,1,0,29,16,13,13,0,0,0,16,3Z" style={{ fill: 'var(--primary)' }} />
                                 <path className="cls-1" d="M20.24,21.66l-4.95-4.95A1,1,0,0,1,15,16V8h2v7.59l4.66,4.65Z" style={{ fill: 'var(--primary)' }} />
                              </svg>
                              <p>3 minute read</p>
                           </div>
                        </div>
                        <div className="post-module__item">
                           <a href="/blog/depression-clinical-studies-near-you" className='post-module__thumb-link'>
                              <img src={Post1} alt="" />
                           </a>
                           <h3 className="post-module__heading">
                              <a href="/blog/depression-clinical-studies-near-you">Navigating Through the Fog: Understanding and Managing Adult Depression</a>
                           </h3>
                           <div className="post-module__read-time">
                              <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                 <path className="cls-1" d="M16,31A15,15,0,1,1,31,16,15,15,0,0,1,16,31ZM16,3A13,13,0,1,0,29,16,13,13,0,0,0,16,3Z" style={{ fill: 'var(--primary)' }} />
                                 <path className="cls-1" d="M20.24,21.66l-4.95-4.95A1,1,0,0,1,15,16V8h2v7.59l4.66,4.65Z" style={{ fill: 'var(--primary)' }} />
                              </svg>
                              <p>3 minute read</p>
                           </div>
                        </div>
                        <div className="post-module__item">
                           <a href="/blog/depression-clinical-studies-near-you" className='post-module__thumb-link'>
                              <img src={Post1} alt="" />
                           </a>
                           <h3 className="post-module__heading">
                              <a href="/blog/depression-clinical-studies-near-you">Navigating Through the Fog: Understanding and Managing Adult Depression</a>
                           </h3>
                           <div className="post-module__read-time">
                              <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                 <path className="cls-1" d="M16,31A15,15,0,1,1,31,16,15,15,0,0,1,16,31ZM16,3A13,13,0,1,0,29,16,13,13,0,0,0,16,3Z" style={{ fill: 'var(--primary)' }} />
                                 <path className="cls-1" d="M20.24,21.66l-4.95-4.95A1,1,0,0,1,15,16V8h2v7.59l4.66,4.65Z" style={{ fill: 'var(--primary)' }} />
                              </svg>
                              <p>3 minute read</p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}

export default Home