import React from 'react';
import './home.scss';

// IMPORT IMAGES
import Post1 from '../../assets/images/post/post-1.jpeg';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

///IMPORT IMAGES
import HeroHome from '../../assets/images/hero_home.svg';
import SearchIcon from '../../assets/images/search_icon.svg';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

// import required modules
import { Navigation } from 'swiper/modules';

const Home = () => {
   return (
      <div className='home__wrapper'>
         <div className="home--hero">
            <div className="hero_bg_image"></div>
            <div className="hero--search__image-wrapper">
               <img src={HeroHome} alt="" />
            </div>

            <div className="hero--search_wrapper">
               <h2 className="hero--search_title">HealthBoost</h2>

               <form action="" className='search--form'>
                  <input type="search" className='search-form__text-input' placeholder='Search' />
                  <button type='submit' className='search-form__search-button'>
                     <img src={SearchIcon} alt="" />
                  </button>
               </form>
            </div>
         </div>

         <div className="scaffold-wrapper">
            <div className="scaffold-wrapper__inner">
               <section className="trending">
                  <h2 className='trending__heading'>Conditions</h2>

                  <Swiper
                     slidesPerView={4}
                     spaceBetween={30}
                     navigation={true}
                     modules={[Navigation]}
                     breakpoints={{
                        0: {
                           slidesPerView: 1.5,
                        },
                        640: {
                           slidesPerView: 2,
                        },
                        825: {
                           slidesPerView: 3,
                        },
                        1100: {
                           slidesPerView: 4,
                        },
                     }}
                     className="trending__carousel"
                  >
                     <SwiperSlide>
                        <a href="/" className='trending__item'>
                           Bipolar 1 and 2: What’s the Difference?
                        </a>
                     </SwiperSlide>
                     <SwiperSlide>
                        <a href="/" className='trending__item'>
                           How Can I Test Myself for COPD?
                        </a>
                     </SwiperSlide>
                     <SwiperSlide>
                        <a href="/" className='trending__item'>
                           5 Trigger Finger Exercises To Ease Pain
                        </a>
                     </SwiperSlide>
                     <SwiperSlide>
                        <a href="/" className='trending__item'>
                           Signs and Symptoms of Lymphoma
                        </a>
                     </SwiperSlide>
                     <SwiperSlide>
                        <a href="/" className='trending__item'>
                           Signs and Symptoms of ADHD in Adults
                        </a>
                     </SwiperSlide>
                     <SwiperSlide>
                        <a href="/" className='trending__item'>
                           Pancolitis Signs and Symptoms
                        </a>
                     </SwiperSlide>
                  </Swiper>
               </section>
            </div>
         </div>

         <div className="scaffold-wrapper">
            <div className="scaffold-wrapper__inner">
               <section className="post-module__wrapper">
                  <h2 className='post-module__heading'>Treatments & Remedies</h2>

                  <div className="post-module__variation">
                     <div className="post-module__item">
                        <a href="/blog/depression-clinical-studies-near-you" className="post-module__image">
                           <img src={Post1} alt="" />
                        </a>
                        <a href="/" className='post-module__key-interest-link'>
                           <span className='post-module__key-interest'>Depression</span>
                        </a>
                        <div className="post-module__title">
                           <a href="/blog/depression-clinical-studies-near-you" className='post-module__title-link'>Navigating Through the Fog: Understanding and Managing Adult Depression</a>
                        </div>
                        <div className="post-module__description">
                           <p>Depression in adults is a common yet often misunderstood condition that affects millions worldwide. It's more than just feeling sad or going through a rough patch; it's a serious mental health issue that can profoundly impact every aspect of an adult's life. This blog aims to shed light on adult depression, its signs, treatment options, and ways to support those who are struggling.</p>
                        </div>
                        <div className="post-read__time">
                           <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                              <path className="cls-1" d="M16,31A15,15,0,1,1,31,16,15,15,0,0,1,16,31ZM16,3A13,13,0,1,0,29,16,13,13,0,0,0,16,3Z" style={{ fill: 'var(--primary)' }} />
                              <path className="cls-1" d="M20.24,21.66l-4.95-4.95A1,1,0,0,1,15,16V8h2v7.59l4.66,4.65Z" style={{ fill: 'var(--primary)' }} />
                           </svg>
                           <p>3 minute read</p>
                        </div>
                     </div>
                     <div className="post-module__item">
                        <a href="/blog/depression-clinical-studies-near-you" className="post-module__image">
                           <img src={Post1} alt="" />
                        </a>
                        <a href="/" className='post-module__key-interest-link'>
                           <span className='post-module__key-interest'>Depression</span>
                        </a>
                        <div className="post-module__title">
                           <a href="/blog/depression-clinical-studies-near-you" className='post-module__title-link'>Navigating Through the Fog: Understanding and Managing Adult Depression</a>
                        </div>
                        <div className="post-module__description">
                           <p>Depression in adults is a common yet often misunderstood condition that affects millions worldwide. It's more than just feeling sad or going through a rough patch; it's a serious mental health issue that can profoundly impact every aspect of an adult's life. This blog aims to shed light on adult depression, its signs, treatment options, and ways to support those who are struggling.</p>
                        </div>
                        <div className="post-read__time">
                           <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                              <path className="cls-1" d="M16,31A15,15,0,1,1,31,16,15,15,0,0,1,16,31ZM16,3A13,13,0,1,0,29,16,13,13,0,0,0,16,3Z" style={{ fill: 'var(--primary)' }} />
                              <path className="cls-1" d="M20.24,21.66l-4.95-4.95A1,1,0,0,1,15,16V8h2v7.59l4.66,4.65Z" style={{ fill: 'var(--primary)' }} />
                           </svg>
                           <p>3 minute read</p>
                        </div>
                     </div>
                     <div className="post-module__item">
                        <a href="/blog/depression-clinical-studies-near-you" className="post-module__image">
                           <img src={Post1} alt="" />
                        </a>
                        <a href="/" className='post-module__key-interest-link'>
                           <span className='post-module__key-interest'>Depression</span>
                        </a>
                        <div className="post-module__title">
                           <a href="/blog/depression-clinical-studies-near-you" className='post-module__title-link'>Navigating Through the Fog: Understanding and Managing Adult Depression</a>
                        </div>
                        <div className="post-module__description">
                           <p>Depression in adults is a common yet often misunderstood condition that affects millions worldwide. It's more than just feeling sad or going through a rough patch; it's a serious mental health issue that can profoundly impact every aspect of an adult's life. This blog aims to shed light on adult depression, its signs, treatment options, and ways to support those who are struggling.</p>
                        </div>
                        <div className="post-read__time">
                           <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                              <path className="cls-1" d="M16,31A15,15,0,1,1,31,16,15,15,0,0,1,16,31ZM16,3A13,13,0,1,0,29,16,13,13,0,0,0,16,3Z" style={{ fill: 'var(--primary)' }} />
                              <path className="cls-1" d="M20.24,21.66l-4.95-4.95A1,1,0,0,1,15,16V8h2v7.59l4.66,4.65Z" style={{ fill: 'var(--primary)' }} />
                           </svg>
                           <p>3 minute read</p>
                        </div>
                     </div>
                     <div className="post-module__item">
                        <a href="/blog/depression-clinical-studies-near-you" className="post-module__image">
                           <img src={Post1} alt="" />
                        </a>
                        <a href="/" className='post-module__key-interest-link'>
                           <span className='post-module__key-interest'>Depression</span>
                        </a>
                        <div className="post-module__title">
                           <a href="/blog/depression-clinical-studies-near-you" className='post-module__title-link'>Navigating Through the Fog: Understanding and Managing Adult Depression</a>
                        </div>
                        <div className="post-module__description">
                           <p>Depression in adults is a common yet often misunderstood condition that affects millions worldwide. It's more than just feeling sad or going through a rough patch; it's a serious mental health issue that can profoundly impact every aspect of an adult's life. This blog aims to shed light on adult depression, its signs, treatment options, and ways to support those who are struggling.</p>
                        </div>
                        <div className="post-read__time">
                           <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                              <path className="cls-1" d="M16,31A15,15,0,1,1,31,16,15,15,0,0,1,16,31ZM16,3A13,13,0,1,0,29,16,13,13,0,0,0,16,3Z" style={{ fill: 'var(--primary)' }} />
                              <path className="cls-1" d="M20.24,21.66l-4.95-4.95A1,1,0,0,1,15,16V8h2v7.59l4.66,4.65Z" style={{ fill: 'var(--primary)' }} />
                           </svg>
                           <p>3 minute read</p>
                        </div>
                     </div>
                     <div className="post-module__item">
                        <a href="/blog/depression-clinical-studies-near-you" className="post-module__image">
                           <img src={Post1} alt="" />
                        </a>
                        <a href="/" className='post-module__key-interest-link'>
                           <span className='post-module__key-interest'>Depression</span>
                        </a>
                        <div className="post-module__title">
                           <a href="/blog/depression-clinical-studies-near-you" className='post-module__title-link'>Navigating Through the Fog: Understanding and Managing Adult Depression</a>
                        </div>
                        <div className="post-module__description">
                           <p>Depression in adults is a common yet often misunderstood condition that affects millions worldwide. It's more than just feeling sad or going through a rough patch; it's a serious mental health issue that can profoundly impact every aspect of an adult's life. This blog aims to shed light on adult depression, its signs, treatment options, and ways to support those who are struggling.</p>
                        </div>
                        <div className="post-read__time">
                           <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                              <path className="cls-1" d="M16,31A15,15,0,1,1,31,16,15,15,0,0,1,16,31ZM16,3A13,13,0,1,0,29,16,13,13,0,0,0,16,3Z" style={{ fill: 'var(--primary)' }} />
                              <path className="cls-1" d="M20.24,21.66l-4.95-4.95A1,1,0,0,1,15,16V8h2v7.59l4.66,4.65Z" style={{ fill: 'var(--primary)' }} />
                           </svg>
                           <p>3 minute read</p>
                        </div>
                     </div>
                  </div>
               </section>
            </div>
         </div>

         <div className="scaffold-wrapper">
            <div className="scaffold-wrapper__inner">
               <section className="post-module__wrapper">
                  <h2 className='post-module__heading'>Body</h2>

                  <div className="post-module__variation post-module__variation-reversed">
                     <div className="post-module__item">
                        <a href="/blog/depression-clinical-studies-near-you" className="post-module__image">
                           <img src={Post1} alt="" />
                        </a>
                        <a href="/" className='post-module__key-interest-link'>
                           <span className='post-module__key-interest'>Depression</span>
                        </a>
                        <div className="post-module__title">
                           <a href="/blog/depression-clinical-studies-near-you" className='post-module__title-link'>Navigating Through the Fog: Understanding and Managing Adult Depression</a>
                        </div>
                        <div className="post-module__description">
                           <p>Depression in adults is a common yet often misunderstood condition that affects millions worldwide. It's more than just feeling sad or going through a rough patch; it's a serious mental health issue that can profoundly impact every aspect of an adult's life. This blog aims to shed light on adult depression, its signs, treatment options, and ways to support those who are struggling.</p>
                        </div>
                        <div className="post-read__time">
                           <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                              <path className="cls-1" d="M16,31A15,15,0,1,1,31,16,15,15,0,0,1,16,31ZM16,3A13,13,0,1,0,29,16,13,13,0,0,0,16,3Z" style={{ fill: 'var(--primary)' }} />
                              <path className="cls-1" d="M20.24,21.66l-4.95-4.95A1,1,0,0,1,15,16V8h2v7.59l4.66,4.65Z" style={{ fill: 'var(--primary)' }} />
                           </svg>
                           <p>3 minute read</p>
                        </div>
                     </div>
                     <div className="post-module__item">
                        <a href="/blog/depression-clinical-studies-near-you" className="post-module__image">
                           <img src={Post1} alt="" />
                        </a>
                        <a href="/" className='post-module__key-interest-link'>
                           <span className='post-module__key-interest'>Depression</span>
                        </a>
                        <div className="post-module__title">
                           <a href="/blog/depression-clinical-studies-near-you" className='post-module__title-link'>Navigating Through the Fog: Understanding and Managing Adult Depression</a>
                        </div>
                        <div className="post-module__description">
                           <p>Depression in adults is a common yet often misunderstood condition that affects millions worldwide. It's more than just feeling sad or going through a rough patch; it's a serious mental health issue that can profoundly impact every aspect of an adult's life. This blog aims to shed light on adult depression, its signs, treatment options, and ways to support those who are struggling.</p>
                        </div>
                        <div className="post-read__time">
                           <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                              <path className="cls-1" d="M16,31A15,15,0,1,1,31,16,15,15,0,0,1,16,31ZM16,3A13,13,0,1,0,29,16,13,13,0,0,0,16,3Z" style={{ fill: 'var(--primary)' }} />
                              <path className="cls-1" d="M20.24,21.66l-4.95-4.95A1,1,0,0,1,15,16V8h2v7.59l4.66,4.65Z" style={{ fill: 'var(--primary)' }} />
                           </svg>
                           <p>3 minute read</p>
                        </div>
                     </div>
                     <div className="post-module__item">
                        <a href="/blog/depression-clinical-studies-near-you" className="post-module__image">
                           <img src={Post1} alt="" />
                        </a>
                        <a href="/" className='post-module__key-interest-link'>
                           <span className='post-module__key-interest'>Depression</span>
                        </a>
                        <div className="post-module__title">
                           <a href="/blog/depression-clinical-studies-near-you" className='post-module__title-link'>Navigating Through the Fog: Understanding and Managing Adult Depression</a>
                        </div>
                        <div className="post-module__description">
                           <p>Depression in adults is a common yet often misunderstood condition that affects millions worldwide. It's more than just feeling sad or going through a rough patch; it's a serious mental health issue that can profoundly impact every aspect of an adult's life. This blog aims to shed light on adult depression, its signs, treatment options, and ways to support those who are struggling.</p>
                        </div>
                        <div className="post-read__time">
                           <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                              <path className="cls-1" d="M16,31A15,15,0,1,1,31,16,15,15,0,0,1,16,31ZM16,3A13,13,0,1,0,29,16,13,13,0,0,0,16,3Z" style={{ fill: 'var(--primary)' }} />
                              <path className="cls-1" d="M20.24,21.66l-4.95-4.95A1,1,0,0,1,15,16V8h2v7.59l4.66,4.65Z" style={{ fill: 'var(--primary)' }} />
                           </svg>
                           <p>3 minute read</p>
                        </div>
                     </div>
                     <div className="post-module__item">
                        <a href="/blog/depression-clinical-studies-near-you" className="post-module__image">
                           <img src={Post1} alt="" />
                        </a>
                        <a href="/" className='post-module__key-interest-link'>
                           <span className='post-module__key-interest'>Depression</span>
                        </a>
                        <div className="post-module__title">
                           <a href="/blog/depression-clinical-studies-near-you" className='post-module__title-link'>Navigating Through the Fog: Understanding and Managing Adult Depression</a>
                        </div>
                        <div className="post-module__description">
                           <p>Depression in adults is a common yet often misunderstood condition that affects millions worldwide. It's more than just feeling sad or going through a rough patch; it's a serious mental health issue that can profoundly impact every aspect of an adult's life. This blog aims to shed light on adult depression, its signs, treatment options, and ways to support those who are struggling.</p>
                        </div>
                        <div className="post-read__time">
                           <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                              <path className="cls-1" d="M16,31A15,15,0,1,1,31,16,15,15,0,0,1,16,31ZM16,3A13,13,0,1,0,29,16,13,13,0,0,0,16,3Z" style={{ fill: 'var(--primary)' }} />
                              <path className="cls-1" d="M20.24,21.66l-4.95-4.95A1,1,0,0,1,15,16V8h2v7.59l4.66,4.65Z" style={{ fill: 'var(--primary)' }} />
                           </svg>
                           <p>3 minute read</p>
                        </div>
                     </div>
                     <div className="post-module__item">
                        <a href="/blog/depression-clinical-studies-near-you" className="post-module__image">
                           <img src={Post1} alt="" />
                        </a>
                        <a href="/" className='post-module__key-interest-link'>
                           <span className='post-module__key-interest'>Depression</span>
                        </a>
                        <div className="post-module__title">
                           <a href="/blog/depression-clinical-studies-near-you" className='post-module__title-link'>Navigating Through the Fog: Understanding and Managing Adult Depression</a>
                        </div>
                        <div className="post-module__description">
                           <p>Depression in adults is a common yet often misunderstood condition that affects millions worldwide. It's more than just feeling sad or going through a rough patch; it's a serious mental health issue that can profoundly impact every aspect of an adult's life. This blog aims to shed light on adult depression, its signs, treatment options, and ways to support those who are struggling.</p>
                        </div>
                        <div className="post-read__time">
                           <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                              <path className="cls-1" d="M16,31A15,15,0,1,1,31,16,15,15,0,0,1,16,31ZM16,3A13,13,0,1,0,29,16,13,13,0,0,0,16,3Z" style={{ fill: 'var(--primary)' }} />
                              <path className="cls-1" d="M20.24,21.66l-4.95-4.95A1,1,0,0,1,15,16V8h2v7.59l4.66,4.65Z" style={{ fill: 'var(--primary)' }} />
                           </svg>
                           <p>3 minute read</p>
                        </div>
                     </div>
                  </div>
               </section>
            </div>
         </div>
      </div>
   )
}

export default Home