import { useEffect } from 'react';
import './App.scss';
import './reset.css';
import $ from 'jquery';

///IMPORT PAGES
import Navbar from './layout/Navbar/Navbar';
import Home from './pages/Home/Home';
import Blog1 from './pages/Blogs/SingleBlog1';
import Footer from './layout/Footer/Footer';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import TermsOfUse from './pages/TermsOfUse/TermsOfUse';

///IMPORT IMAGES
import ArrowIcon from './assets/images/breadcrumb-chevron-icon.svg';

///REACT ROUTER DOM
import { Routes, Route, BrowserRouter as Router, Navigate } from 'react-router-dom';

function App() {

  useEffect(() => {
    $(window).on('scroll', (e) => {
      if(window.scrollY > 200) {
        $('.scroll-top').addClass('scroll-top__visible');
      } else {
        $('.scroll-top').removeClass('scroll-top__visible');
      }
    });

    $('.scroll-top').on('click', () => {
      window.scrollTo(0, 0)
    })
  })

  return (
    <Router>
      <div className='application-outlet'>
        <div className="scroll-top">
          <img src={ArrowIcon} alt="" />
        </div>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/blog/depression-clinical-studies-near-you" element={<Blog1 />} />
          <Route path="/terms/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms/terms-of-use" element={<TermsOfUse />} />
          <Route
            path="*"
            element={<Navigate to="/" replace />}
          />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
