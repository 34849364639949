import React from 'react';
import './navbar.scss';

///IMport Images
import Logo from '../../assets/images/logo.svg';

const Navbar = () => {
   return (
      <div className="header">
         <div className="header-inner">
            <a href="/">
               <img src={Logo} alt="" />
            </a>

            <div className="nav-items">
               <a href="/terms/privacy-policy" className='nav__link'>Privacy Policy</a>
               <div className="circle-pointer-small"></div>
               <a href="/terms/terms-of-use" className='nav__link'>Terms</a>
            </div>
         </div>
      </div>
   )
}

export default Navbar