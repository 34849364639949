import React from 'react';
import './footer.scss';

///Import Images
import Logo from '../../assets/images/logo_white.svg';

const Footer = () => {

   let date = new Date;
   let year = date.getFullYear();

   return (
      <footer className='scaffold-wrapper'>
         <div className="scaffold-wrapper__inner">
            <div className="footer--top">
               <div className="footer-logo">
                  <img src={Logo} alt="" />
               </div>
               <div className="footer-nav__items">
                  <a href="/terms/privacy-policy" className='footer__link'>Privacy Policy</a>
                  <a href="/terms/terms-of-use" className='footer__link'>Terms</a>
               </div>
            </div>

            <div className="footer--body">
               <div className="footer-body__left">
                  <p>Disclaimer: The information on this site is not intended or implied to be a substitute for professional financial advice. All content, including text, graphics, images and information, contained on or available through this web site is for general information purposes only.</p>
                  <span>Made with ♥ love in Miami, FL</span>
               </div>

               <div className="footer-body__right">
                  <p>© {year} Clinical Boost LP.<br/> <span>All Rights Reserved.</span></p>
               </div>
            </div>
         </div>
      </footer>
   )
}

export default Footer