import React from 'react';

const PrivacyPolicy = () => {
  return (
      <div className="terms__wrapper">
         <p className='desc__text'>TERMS OF SERVICE</p>
         <p className="desc__text">Last Update: April 17, 2022</p>
         <br/>
         <p className='desc__text desc-with__title'>ACCEPTANCE</p>
         <p className="desc__text">Please read these Terms of Service (the “Terms”) carefully because they contain important disclosures and disclaimers. These Terms govern your use of www.clinicalboost.digital (the “Site”) and its materials. These Terms have a force of a legally binding agreement, even if you are simply browsing without intention to follow any of our diet programs, purchase anything from the Site or contact us. The term "us," "we" or "our" refers to Evelen Marketing, Inc. The term "you" refers to the viewer of the Site. Privacy Policy [www.clinicalboost.digital] is another important document that you should familiarize yourself with because it describes our practices with respect to your personal information. You cannot visit the Site if you do not agree to these Terms or the Privacy Policy. Sometimes we modify these Terms. We don’t notify users about every change to the Terms but you can see the date of the last update at the top of this page. If you still wish to visit the Site after said date, that constitutes your agreement to the updates.</p>
          <br/>
          <p className='desc__text desc-with__title'>DISCLOSURES</p>
          <p className='desc__text desc-with__title'>No Medical Advice. We are a digital marketing agency for clinical trials. Our role is limited to providing referrals. NOTHING ON THIS SITE CONSTITUTES MEDICAL ADVICE. We disclaim any liability for any information or material that is or may become a part of this Site. Nothing on this Site is intended to diagnose, treat or prevent any medical condition or its symptoms. Consult your doctor before relying on anything you read on our Site. The materials presented here in no way substitute medical counselling. You must not avoid or delay medical treatment because of anything contained on this Site. If you think you may have a medical emergency, call your doctor or emergency medical services immediately.</p>
          <p className='desc__text desc-with__title'>Interactive Features. This Site includes interactive features that allow users to communicate with us. You agree that, because of the limited nature of such communication, any guidance you may receive is likely to be incomplete and may even be misleading. Therefore, any assistance you may receive using any our Site’s interactive features does not constitute specific advice and should not be relied upon without further competent independent confirmation.</p>
          <p className='desc__text desc-with__title'>Third Parties. The Site contains references to entities unaffiliated with our Site. Such references does not mean that we endorse those third parties or vice versa. Any opinions, advice, statements, services, offers or other information expressed or made available by third parties, are those of the respective author(s) and do not necessarily reflect our views, findings or opinions.</p>
          <br/>
          <p className='desc__text desc-with__title'>INTELLECTUAL PROPERTY</p>
          <p className='desc__text desc-with__title'>Our Intellectual Property. We and our content suppliers own all intellectual property rights in our Site contents, logos, trademarks (whether registered or unregistered) and data. By using our Site you do not acquire any of our IP rights. Nevertheless, you can view and print out this Site’s content for personal use. We reserve all rights that are not expressly granted under these Terms or other written agreements between you and us.</p>
          <p className='desc__text desc-with__title'>Your Submissions. If you upload any content to the public areas of our Site (e.g. comments to blog posts), you state that: (i) it is true, accurate and not misleading, (ii) you have all necessary rights to that content, and (iii) we can display, edit, erase or distribute this content without compensation to you. We can use and implement any feedback that you voluntarily provide without compensation to you.</p>
          <p className='desc__text desc-with__title'>Copyright Infringement. We take copyright infringement seriously. Report it to us if you see it on our Site and we will investigate.</p>
          <br/>
          <p className='desc__text desc-with__title'>USERS’ OBLIGATIONS</p>
          <p className='desc__text'>By visiting this Site, you represent and agree that:</p>
          <p className='desc__text desc-with__title'>You have a full capacity to enter into a legally binding agreement, such as these Terms.</p>
          <p className='desc__text desc-with__title'>You will not let others use your account, except as may be explicitly authorized by us. Everything that happens under your account is your responsibility. Registering duplicate accounts is not allowed.</p>
          <p className='desc__text desc-with__title'>If you make a submission, it shall be truthful and not misleading. We can terminate any account for writing untruthful posts, product/diet reviews, comments or other content. We reserve the right to edit, reject or erase anything submitted to us without prior notice. You will not send spam, anything defamatory, vulgar, racist, abusive or hateful.</p>
          <p className='desc__text desc-with__title'>You will ask for our permission before copying anything from our Site for republication.</p>
          <p className='desc__text desc-with__title'>You will not use our Site for anything illegal.</p>
          <p className='desc__text desc-with__title'>We reserve the right to terminate any account using our sole reasonable discretion and without notice or liability.</p>
          <p className='desc__text desc-with__title'>Bots, crawlers, indexers, web spiders, harvesters or any similar automatic processes are not allowed on our Site.</p>
          <p className='desc__text desc-with__title'>You will not impede the proper functioning of the Site.</p>
          <br/>
          <p className='desc__text desc-with__title'>BREACH OF THESE TERMS</p>
          <p className='desc__text'>If any user violates these Terms or any law, we can, without limitation: (i) ban that user from the Site; (ii) disclose the user’s identity to authorities and assist in investigations; (iii) delete or moderate the user’s content; (iv) take any other action available under law.</p>
          <br/>
          <p className='desc__text desc-with__title'>DISCLAIMER OF WARRANTY; LIMITATION OF LIABILITY</p>
          <p className='desc__text desc-with__title'>ALL SITE CONTENT IS PROVIDED ON AN “AS IS” BASIS, TO BE RELIED ON AT YOUR OWN RISK. DO YOUR OWN RESEARCH AND CONSULT YOUR HEALTHCARE PROVIDER BEFORE RELYING ON ANYTHING ON THIS SITE.  WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUALITY, NON-INFRINGEMENT, SAFETY, FREEDOM FROM DEFECTS OR THAT DEFECTS WILL BE CORRECTED, UNINTERRUPTED, VIRUS-FREE OR ERROR-FREE PERFORMANCE.</p>
          <p className='desc__text desc-with__title'>WE ARE NOT LIABLE FOR INDIRECT, SPECIAL, INCIDENTAL OR CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS AND PROPERTY DAMAGE, EVEN IF WE WERE ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, NOR SHALL WE BE HELD LIABLE FOR DELAY OR FAILURE IN PERFORMANCE RESULTING FROM CAUSES BEYOND OUR REASONABLE CONTROL.  IN NO EVENT SHALL OUR TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES AND CAUSES OF ACTION EXCEED $100.  SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OF CERTAIN WARRANTIES, SO THE ABOVE LIMITATIONS IN THIS SECTION MAY NOT APPLY TO YOU.</p>
          <br/>
          <p className='desc__text desc-with__title'>INDEMNIFICATION</p>
          <p className='desc__text'>You agree to defend, indemnify and hold harmless our company, its officers, directors, employees and agents, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney's fees) arising from: (i) your use of and access to the Site; (ii) your violation of any provision of these Terms; (iii) your violation of any third party right, including without limitation any copyright, property, or privacy right; or (iv) any claim that one of your user submissions caused damage to a third party.</p>
          <br/>
          <p className='desc__text desc-with__title'>GOVERNING LAW AND JURISDICTION</p>
          <p className='desc__text'>These Terms are governed by and shall be construed in accordance with the laws of Florida. The courts located in Miami, FL shall have exclusive jurisdiction to settle any dispute or claim arising out of or in connection with these Terms.</p>
          <br/>
          <p className='desc__text desc-with__title'>GENERAL</p>
          <p className='desc__text desc-with__title'>Communications. You agree that we can communicate with you electronically. All electronic communications shall have the same legal force as if they were in paper form.</p>
          <p className='desc__text desc-with__title'>Hyperlinks. Linking to our Site is allowed, however, it must always be done in a way that does not adversely affect our business or implies some form of association when there is none.</p>
          <p className='desc__text desc-with__title'>Severability. If any part of these Terms is found to be unenforceable, then only that particular portion, and not the entire Terms, will be unenforceable.</p>
          <p className='desc__text desc-with__title'>Assignment. We have the right, at our sole discretion, to assign or subcontract our rights or obligations outlined in these Terms.</p>
          <p className='desc__text desc-with__title'>Waiver. Our failure to exercise any of our rights under these Terms shall not be considered a waiver to exercise them in other instances. No waiver shall be effective unless it is in writing signed by us.</p>
          <p className='desc__text desc-with__title'>Prevailing Language. If there are any inconsistencies or conflicts between the English original of these Terms and any foreign language translation, the English version shall prevail.</p>


      </div>
  )
}

export default PrivacyPolicy